import React, {lazy, Suspense} from 'react';
import './sass/main.scss';

import Loading from './components/loading';
import somos_verano from './images/somos-verano.jpg';
const HeaderHinchables  = lazy(() => import('./components/header-hinchables'));
const Hinchables = lazy(() => import('./components/hinchables'));
const SomosVerano = lazy(() => import('./components/somos-verano'));


function App() {
  return (
      <div className="App">
      <Suspense fallback={<Loading />}>
      <HeaderHinchables />
      <Hinchables />
      <SomosVerano />
      <div className='separador footer'>
          <picture>
            <img src={somos_verano} className="somosverano" alt="Somos el verano"/>
          </picture>
      </div>
      <div className='footer-app'>
        <p>&copy; INTEX Todos los derechos reservados</p>
      </div>
      </Suspense>
    </div>
  );
}

export default App;
